import type { MaybeRef, Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

// composables
import useTimeBus from 'web/src/modules/core/composables/time-bus/useTimeBus';
import { useSyncSportlineWithoutCTag } from 'web/src/modules/sportline/submodules/sync-sportline';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
// types
import type {
  LoadStatisticIdentifier,
  TeamsConfrontationStatisticBlockData,
} from 'web/src/modules/sportline/submodules/event-statistic/types';
import type {
  BetlinePostMatchStatistics,
  CoreSportlineFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import type { SportlinePostMatchStatistics } from 'web/src/modules/sportline/types/statistics';
// enums
import { BetlineSportEventStatus } from 'web/src/modules/sportline/enums/rest';
// utils
import { getBetlineMatchStatistics } from 'web/src/modules/sportline/utils/api';
import {
  convertBetlineToSportlinePostMatchStatistics,
  isStatisticNotFoundError,
} from 'web/src/modules/sportline/utils/statistic';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

import parsePostMatchStatisticConfrontationBlocks from '../../utils/parsePostMatchStatisticConfrontationBlocks';
import { createWatchBackgroundRequestsCondition } from '../utils';

interface UseSportlinePostMatchStatisticsStoreComposableProps {
  isSportEventStatisticEnabled: Ref<boolean>;
  expectedStatisticId: Ref<Maybe<number>>;
  currentLoadedMatchIdentifier: Ref<Maybe<false | LoadStatisticIdentifier>>;
  sportlineEventStatisticUpdateInterval: Ref<number>;
  isBackgroundRequestPriorityIncreased?: MaybeRef<boolean>;
  setIsBackgroundRequestPriorityIncreased(value: boolean): void;
}

interface UseSportlinePostMatchStatisticsStoreComposableComposable {
  initialRequests(): Promise<void>;
  onInit(): void;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): Promise<void>;
  /** @deprecated use postMatchStatistics instead */
  betlinePostMatchStatistics: Ref<Maybe<BetlinePostMatchStatistics | false>>;
  postMatchStatistics: Ref<Maybe<SportlinePostMatchStatistics> | false>;
  hasPostMatchStatistics: Ref<boolean>;
  isPostMatchStatisticLoaded: Ref<boolean>;
  confrontationBlocks: Ref<TeamsConfrontationStatisticBlockData[]>;
  isSyncStateAvailable: Ref<boolean>;
  setBetlinePostMatchStatistics(statistic: Maybe<BetlinePostMatchStatistics | false>): void;
}

const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;

export function useSportlinePostMatchStatisticsStoreComposable(
  props: UseSportlinePostMatchStatisticsStoreComposableProps,
): UseSportlinePostMatchStatisticsStoreComposableComposable {
  const {
    expectedStatisticId,
    isSportEventStatisticEnabled,
    currentLoadedMatchIdentifier,
    sportlineEventStatisticUpdateInterval,
    setIsBackgroundRequestPriorityIncreased,
  } = props;

  const { now } = useTimeBus();

  const isBackgroundRequestPriorityIncreased = toRef(props.isBackgroundRequestPriorityIncreased ?? null);

  /** @deprecated use postMatchStatistics instead */
  const betlinePostMatchStatistics = ref<Maybe<BetlinePostMatchStatistics | false>>(null);
  const postMatchStatistics = ref<Maybe<SportlinePostMatchStatistics> | false>(null);

  const setBetlinePostMatchStatistics = function setBetlinePostMatchStatistics(
    statistic: Maybe<BetlinePostMatchStatistics | false>,
  ): void {
    postMatchStatistics.value = convertBetlineToSportlinePostMatchStatistics(statistic);
    betlinePostMatchStatistics.value = statistic;
  };
  const hasPostMatchStatistics = computed<boolean>(() => {
    const targetId = expectedStatisticId.value;
    const statistic = postMatchStatistics.value ? postMatchStatistics.value : null;
    const loadedId = statistic?.id;
    return !!loadedId && String(targetId) === loadedId;
  });
  const isPostMatchStatisticLoaded = computed<boolean>(() => {
    if (!isSportEventStatisticEnabled.value) {
      return true;
    }

    if (postMatchStatistics.value === false) {
      return true;
    }

    const targetId = expectedStatisticId.value;

    if (!targetId) {
      return false;
    }

    return hasPostMatchStatistics.value;
  });
  const confrontationBlocks = computed<TeamsConfrontationStatisticBlockData[]>(() => {
    const statistic = postMatchStatistics.value ? postMatchStatistics.value : null;
    const periods = statistic?.periods;
    return parsePostMatchStatisticConfrontationBlocks(periods);
  });

  const lastUpdate = new BackgroundUpdateStopwatch<['postMatchStatistics']>({
    postMatchStatistics: async ({ silent }: CoreSportlineFetchOptions) => {
      if (!isSportEventStatisticEnabled.value) {
        return;
      }

      try {
        const eventId = expectedStatisticId.value;

        if (!eventId) {
          return;
        }

        setBetlinePostMatchStatistics(
          await getBetlineMatchStatistics({ eventId }, { silent }),
        );
        setIsBackgroundRequestPriorityIncreased(false);
      } catch (error: unknown) {
        if (!isStatisticNotFoundError(error)) {
          throw error;
        }

        // we tried but got empty statistic for this ID
        setBetlinePostMatchStatistics(false);
      } finally {
        lastUpdate.update('postMatchStatistics');
      }
    },
  });

  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled: setBackgroundUpdateEnabledOriginal,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: sportlineEventStatisticUpdateInterval,
  });

  const isSyncStateAvailable = computed<boolean>(() => {
    if (!isSportEventStatisticEnabled.value) {
      return false;
    }

    // load post match data only for closed events
    const loadedSportEvent = currentLoadedMatchIdentifier.value;

    if (loadedSportEvent !== false && loadedSportEvent?.status !== BetlineSportEventStatus.CLOSED) {
      return false;
    }

    if (isBackgroundRequestPriorityIncreased.value) {
      // Always do background requests after event moved to CLOSED
      return true;
    }

    // sync only if we have post data
    if (!postMatchStatistics.value) {
      return false;
    }

    // skip syncs after 24 hours
    const timestamp = (postMatchStatistics.value || null)?.matchDate;
    const diff = timestamp ? now.value - timestamp : null;

    return diff !== null && diff <= TWENTY_FOUR_HOURS;
  });

  function onInit(): void {
    useSyncSportlineWithoutCTag(async (silent: boolean): Promise<void> => {
      if (isSyncStateAvailable.value) {
        await syncState({ silent });
      }
    }, sportlineEventStatisticUpdateInterval);
  }

  const { setBackgroundUpdateEnabled } = createWatchBackgroundRequestsCondition(
    isSyncStateAvailable,
    setBackgroundUpdateEnabledOriginal,
  );

  return {
    initialRequests,
    onInit,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    betlinePostMatchStatistics,
    postMatchStatistics,
    hasPostMatchStatistics,
    isPostMatchStatisticLoaded,
    confrontationBlocks,
    isSyncStateAvailable,
    setBetlinePostMatchStatistics,
  };
}
