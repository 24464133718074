import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { SportlineType } from 'web/src/modules/sportline/enums';

interface UseMiniBoardStoreComposableProps {
  sportlineEvent: Ref<Maybe<{ type: SportlineType }>>;
}

interface UseSportlineEventDetailsMiniBoardStoreComposable {
  isMiniBoardShown: Ref<boolean>;
  isMiniBoardAllowed: Ref<boolean>;
  setIsMiniBoardShown(value: boolean): void;
}

export function useSportlineEventDetailsMiniBoardStoreComposable(
  props: UseMiniBoardStoreComposableProps,
): UseSportlineEventDetailsMiniBoardStoreComposable {
  const { sportlineEvent } = props;

  const isMiniBoardShown = ref<boolean>(false);

  const isMiniBoardAllowed = computed(() => sportlineEvent.value?.type === SportlineType.Live);

  function setIsMiniBoardShown(value: boolean): void {
    isMiniBoardShown.value = value;
  }

  return {
    isMiniBoardShown,
    isMiniBoardAllowed,
    setIsMiniBoardShown,
  };
}
