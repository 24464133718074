import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type { SportEventDetailsPreview } from 'web/src/modules/sportline/types';

export function getSportEventDetailsFromPreviewData(
  preview?: Maybe<SportEventDetailsPreview>,
  options?: { actualSportEventId?: Maybe<string> },
): Maybe<Readonly<SportEventDetails>> {
  const {
    sport,
    league,
    region,
    sportEvent,
  } = preview || {};

  if (!sport || !league || !region || !sportEvent) {
    return null;
  }

  if (sportEvent.id !== options?.actualSportEventId) {
    return null;
  }

  return {
    ...sportEvent,
    sport,
    league,
    region,
  };
}
