<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { SportBackgroundColorDark } from 'web/src/modules/sportline/constants';
import { SportFamily } from 'web/src/modules/sportline/enums';

interface PowerPlayBadgeProps {
  count: number;
}

const props = defineProps<PowerPlayBadgeProps>();
const count = toRef(props, 'count');
const label = computed<string>(() => (count.value > 1 ? `PP${count.value}` : 'PP'));
const textColor = SportBackgroundColorDark[SportFamily.IceHockey];
</script>

<template>
  <span v-auto-id="'PowerPlayBadge'" :class="$style['power-play-badge']">
    <span
      :class="$style['power-play-badge__label']"
      :style="{ color: textColor }"
    >{{ label }}</span>
  </span>
</template>

<style module lang="scss">
.power-play-badge {
  display: flex;
  flex-direction: column;
  padding: 2px 0;

  &__label {
    @include bold\9\12\02\caps;

    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: 0 2px;
    background: #9cc6ee;
    border-radius: 2px;
  }
}
</style>
