import type { Sport, SportlineEvent } from 'web/src/modules/sportline/types';
import type { SportlinePostMatchStatistics } from 'web/src/modules/sportline/types/statistics';
import { SportlineType } from 'web/src/modules/sportline/enums';

export function buildPostMatchStatisticsFromSportlineEvent({ sportlineEvent, sport }: {
  sportlineEvent: Maybe<SportlineEvent>;
  sport: Maybe<Sport>;
}): Maybe<SportlinePostMatchStatistics> {
  if (!sportlineEvent || !sport) { return null; }

  return {
    id: sportlineEvent.id,
    matchDate: sportlineEvent.kickoff,
    family: sport.representation.family,
    resultScore: (sportlineEvent.type === SportlineType.Live)
      ? [sportlineEvent.matchProgress.totalScore.host, sportlineEvent.matchProgress.totalScore.guest].join(':')
      : null,
    cards: [],
    substitutions: [],
    teams: sportlineEvent.competitors.map((competitor) => ({
      id: competitor.id,
      name: competitor.name,
      logo: competitor.logo,
    })),
    goals: [],
    scores: [],
    periods: [],
  };
}
