import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Sport, SportlineEvent } from 'web/src/modules/sportline/types';
import { SportlineType, SportProgressBarType } from 'web/src/modules/sportline/enums';

interface UseDoShowMatchProgressProps {
  sport: Ref<Sport>;
  sportlineEvent: Ref<SportlineEvent>;
}

interface UseDoShowMatchProgressComposable {
  doShowTimeline: Ref<boolean>;
}

export function useDoShowMatchProgress(
  props: UseDoShowMatchProgressProps,
): UseDoShowMatchProgressComposable {
  const { sport, sportlineEvent } = props;

  const doShowTimeline = computed(() => (
    sport.value.representation.progressBarType === SportProgressBarType.Linear
    && sportlineEvent.value.type === SportlineType.Live
    && !!sportlineEvent.value.periods?.length
  ));

  return { doShowTimeline };
}
