import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';

interface UseContentHasStatisticsStateComposable {
  hasMatchStatistic: Ref<boolean>;
  hasPostMatchStatistic: Ref<boolean>;
}

export function useContentHasStatisticsState(): UseContentHasStatisticsStateComposable {
  const statisticsStore = useSportlineEventStatisticStore();

  return {
    hasMatchStatistic: toRef(() => statisticsStore.hasMatchStatistic),
    hasPostMatchStatistic: toRef(() => statisticsStore.hasPostMatchStatistics),
  };
}
