import type { Ref } from 'vue';
import { computed, ref } from 'vue';

// composables
import { useTheme } from '@core/theme';

import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import {
  useSportlineEventId,
  useSportlineEventTypeRef,
} from 'web/src/modules/sportline/composables/sportline-event';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { useSportlineCacheStorage } from 'web/src/modules/sportline/submodules/cache';
import { useStreamUrlStoreComposable } from 'web/src/modules/sportline/submodules/streams/store/composables';
// types
import type { SportlineEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type { GetEventStatusID } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/types';
import type { UseStreamUrlStoreComposable } from 'web/src/modules/sportline/submodules/streams/store/composables';
import type {
  League,
  Region,
  Sport,
  SportElement,
  SportEventDetailsPreview,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import type { CoreSportEventResponse, CoreSportlineFetchOptions } from 'web/src/modules/sportline/types/rest';
// enums
import type { BetlineSportEventStatus } from 'web/src/modules/sportline/enums/rest';
import type { GetEventSubscriptionStatus } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
// utils
import { getSportEventDetailsFromPreviewData } from 'web/src/modules/sportline/submodules/event-details/utils';
import { buildSportlineSportElement, DetailsAdapter } from 'web/src/modules/sportline/utils/rest';

import { createFetchDetailsMethod } from '../utils';

interface UseSportlineEventDetailsDataStoreComposableProps {
  pageIdentifier: Ref<SportEventsPageIdentifier>;
  fireRequestStatus(id: GetEventStatusID, status: GetEventSubscriptionStatus): void;
}

interface UseSportlineEventDetailsDataStoreComposableEvents {
  sportEventDetailsUpdated(value: Maybe<CoreSportEventResponse | false>): void;
}

interface UseSportlineEventDetailsDataStoreComposable {
  isLoaded: Ref<boolean>;
  isShowingLoadingIndicator: Ref<boolean>;
  rawSportEventDetails: Ref<Maybe<CoreSportEventResponse | false>>;
  sportEventDetailsPreview: Ref<Maybe<SportEventDetailsPreview>>;
  sportEventDetails: Ref<Maybe<SportlineEventDetails>>;
  sportEventDetailsId: Ref<Maybe<string>>;
  sportEventDetailsLoadedId: Ref<Maybe<string>>;
  sportElement: Ref<Maybe<SportElement>>;
  sport: Ref<Maybe<Sport>>;
  region: Ref<Maybe<Region>>;
  league: Ref<Maybe<League>>;
  setDetailsStatus(status: BetlineSportEventStatus): void;
  clearDetailsMarketsList(): void;
  setRawSportEventDetails(value: Maybe<CoreSportEventResponse | false>): void;
  fetchDetails(options: CoreSportlineFetchOptions): Promise<void>;
  onInit(): void;
  streamInfo: UseStreamUrlStoreComposable;
}

export function useSportlineEventDetailsDataStoreComposable(
  props: UseSportlineEventDetailsDataStoreComposableProps,
  events: UseSportlineEventDetailsDataStoreComposableEvents,
): UseSportlineEventDetailsDataStoreComposable {
  const { pageIdentifier, fireRequestStatus } = props;

  const { theme } = useTheme();
  const {
    getSportEventCache,
    getLeagueCacheByKey,
    getRegionCacheByKey,
    getSportCacheByKey,
    getMarketCache,
  } = useSportlineCacheStorage();
  const parseSportlineSettings = useParseSportlineSettingsRef();
  const apiService = useSportlineApiService();
  const broadcastSelected = useBroadcastSelected();

  const rawSportEventDetails = ref<Maybe<CoreSportEventResponse | false>>(null);
  const isShowingLoadingIndicator = ref(false);

  const sportEventDetailsLoadedId = computed<Maybe<string>>(() => (
    rawSportEventDetails.value ? String(rawSportEventDetails.value.id) : null
  ));
  /** Return is load data for target id completed (have data or missing event) */
  const isLoaded = computed<boolean>(() => {
    if (rawSportEventDetails.value === null) {
      return false;
    }

    // load missing event
    if (rawSportEventDetails.value === false) {
      return true;
    }

    const targetId = pageIdentifier.value.sportEventId;

    return !!targetId && !!sportEventDetailsLoadedId.value
      && targetId === sportEventDetailsLoadedId.value;
  });
  const sportEventDetailsPreview = computed<Maybe<SportEventDetailsPreview>>(() => {
    const targetId = pageIdentifier.value.sportEventId;

    if (!targetId) {
      return null;
    }

    const sportEventCache = getSportEventCache(targetId);

    if (!sportEventCache.value) {
      return null;
    }

    const leagueCache = getLeagueCacheByKey(sportEventCache.value.leagueKey);
    const regionCache = getRegionCacheByKey(sportEventCache.value.regionKey);
    const sportCache = getSportCacheByKey(sportEventCache.value.sportKey);

    if (!leagueCache?.value || !regionCache?.value || !sportCache?.value) {
      return null;
    }

    const marketsCache = getMarketCache(targetId);
    const { markets, marketGroups, moreCount } = marketsCache?.value?.markets ?? {};

    return {
      sport: sportCache.value.sport,
      region: regionCache.value.region,
      league: leagueCache.value.league,
      sportEvent: {
        ...sportEventCache.value.sportEvent as SportlineEvent,
        markets: markets ?? [],
        marketGroups: marketGroups ?? [],
        moreCount: moreCount ?? {},
      },
    };
  });
  const sportEventDetails = computed<Maybe<SportlineEventDetails>>(() => {
    const loadedId = rawSportEventDetails.value ? String(rawSportEventDetails.value.id) : null;
    const targetId = pageIdentifier.value.sportEventId;

    if (rawSportEventDetails.value === null || loadedId !== targetId) {
      // return preview for unloaded event or mismatched id
      return getSportEventDetailsFromPreviewData(
        sportEventDetailsPreview.value,
        { actualSportEventId: targetId },
      );
    }

    if (rawSportEventDetails.value === false) {
      return null;
    }

    return (new DetailsAdapter(rawSportEventDetails.value, parseSportlineSettings.value))
      .sportEventDetails;
  });

  const sportEventDetailsId = useSportlineEventId(sportEventDetails);
  const sportlineType = useSportlineEventTypeRef(sportEventDetails);

  const streamInfo = useStreamUrlStoreComposable({ sportlineEventId: sportEventDetailsId, sportlineType });

  const sportElement = computed<Maybe<SportElement>>(() => (
    rawSportEventDetails.value
      ? buildSportlineSportElement(rawSportEventDetails.value, parseSportlineSettings.value)
      : null
  ));

  const sport = computed<Maybe<Sport>>(() => sportEventDetails.value?.sport ?? null);
  const region = computed<Maybe<Region>>(() => sportEventDetails.value?.region ?? null);
  const league = computed<Maybe<League>>(() => sportEventDetails.value?.league ?? null);

  function setRawSportEventDetails(value: Maybe<CoreSportEventResponse | false>): void {
    rawSportEventDetails.value = value;
    events.sportEventDetailsUpdated(value);
  }

  function setDetailsStatus(status: BetlineSportEventStatus): void {
    const details = rawSportEventDetails.value;

    if (!details) {
      return;
    }

    setRawSportEventDetails({ ...details, status });
  }

  function clearDetailsMarketsList(): void {
    const details = rawSportEventDetails.value;

    if (!details) {
      return;
    }

    setRawSportEventDetails({ ...details, markets: [], marketsCount: 0 });
  }

  const internalFetchDetails = createFetchDetailsMethod(apiService, {
    currentResponse: rawSportEventDetails,
    loadedId: sportEventDetailsLoadedId,
    fireRequestStatus,
    beforeRequest(): void {
      if (!isLoaded.value) {
        isShowingLoadingIndicator.value = true;
        streamInfo.clearStreamInfo();
      }
    },
    onMissingEvent(eventId: string): void {
      isShowingLoadingIndicator.value = false;
      broadcastSelected.removeEventsDataInStorage([eventId]);
    },
  });

  async function fetchDetails({ silent }: CoreSportlineFetchOptions): Promise<void> {
    const result = await internalFetchDetails({
      silent,
      eventId: pageIdentifier.value.sportEventId,
      theme: theme.value,
    });

    if (!result) {
      return;
    }

    isShowingLoadingIndicator.value = false;
    setRawSportEventDetails(result);
    broadcastSelected.updateDataInStorageByResponse({
      response: { events: [result], totalCount: 1 },
    });
  }

  const {
    onInit: onSportlineEventsChangesListenersInit,
  } = useSportlineEventsChangesListeners({
    setResponse(response: CoreSportEventResponse): void {
      rawSportEventDetails.value = response;
    },
    getResponse(): Maybe<CoreSportEventResponse> {
      // eslint-disable-next-line ts/prefer-nullish-coalescing
      return rawSportEventDetails.value || null;
    },
  });

  function onInit(): void {
    onSportlineEventsChangesListenersInit();
  }

  return {
    isLoaded,
    isShowingLoadingIndicator,
    rawSportEventDetails,
    sportEventDetailsPreview,
    sportEventDetails,
    sportEventDetailsId,
    sportEventDetailsLoadedId,
    sportElement,
    sport,
    region,
    league,
    setDetailsStatus,
    clearDetailsMarketsList,
    setRawSportEventDetails,
    fetchDetails,
    onInit,
    streamInfo,
  };
}
