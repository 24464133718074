import type { AppErrorCode } from '@leon-hub/app-errors';
import type { AbstractErrorCode, AbstractErrorOptions } from '@leon-hub/errors';
import type { Without } from '@leon-hub/types';
import { AbstractError } from '@leon-hub/errors';

import type { StreamErrorDescription } from '../types';
import { StreamErrorCode } from './StreamErrorCode';

interface StreamErrorExtensions {
  message?: string;
  keyTitle?: string;
  keySubTitle?: string;
}

interface StreamErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  defaultTitle: string;
  defaultSubtitle?: string;
  code?: AppErrorCode;
  originalError?: Error & { extensions?: StreamErrorExtensions; code?: AbstractErrorCode };
}

export class StreamError extends AbstractError implements StreamErrorDescription {
  readonly title: string;

  readonly subtitle: Maybe<string>;

  constructor(options: StreamErrorOptions) {
    const extensions = options.originalError?.extensions;

    super({
      ...options,
      message: options.message ?? extensions?.message,
      code: options.code ?? options.originalError?.code ?? StreamErrorCode.STREAM_NOT_AVAILABLE,
    });

    this.title = extensions?.keyTitle ?? options.defaultTitle;
    this.subtitle = extensions?.keySubTitle ?? options.defaultSubtitle ?? null;
  }
}
