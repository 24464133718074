/**
 * On first page loading:
 * - if we've got event data then it shows the page
 * - if we've got a MISSING status then it redirects up by the sport's tree
 * - if we've got an error (like 500) then it counts up to 3 and shows the connection error snackbar
 *
 * While viewing a page:
 * - if we've got event data then it shows the page
 * - if we've got a MISSING status then it counts up to 3. After the first status event will be suspended.
 *   Then we close it and show a suspended event page (@see LEONWEB-13493).
 * - If we've got an error (like 500) then we ignore it (like the lost connection)
 */
import type {
  GetEventFailedRequestsCounter,
  GetEventStatusID,
  OnGetEventRequestBehaviour,
} from './types';
import {
  GetEventSubscriptionStatus,
} from './enums';

interface StatusCounter {
  status: GetEventSubscriptionStatus;
  count: number;
}

export function createGetEventFailedRequestsCounter(): GetEventFailedRequestsCounter {
  let subscribers: OnGetEventRequestBehaviour[] = [];
  const lastStatusMap: Record<GetEventStatusID, Optional<StatusCounter>> = {};

  function getSubscribersIds(): GetEventStatusID[] {
    return subscribers.map((subscriber) => subscriber.id);
  }

  function subscribe(subscriber: OnGetEventRequestBehaviour): void {
    subscribers.push(subscriber);
  }

  function updateLastStatusCount(id: GetEventStatusID, status: GetEventSubscriptionStatus): StatusCounter {
    let lastStatus = lastStatusMap[id];

    if (!lastStatus || lastStatus.status !== status) {
      lastStatus = { status, count: 0 };
      lastStatusMap[id] = lastStatus;
    }

    lastStatus.count += 1;

    return lastStatus;
  }

  function fireStatus(id: GetEventStatusID, status: GetEventSubscriptionStatus): void {
    const counter = updateLastStatusCount(id, status);
    for (const subscriber of subscribers) {
      if (subscriber.id !== id) { continue; }
      void subscriber.onStatus(counter.status, counter.count);
    }
  }

  function fireStatusAllExcept(id: GetEventStatusID, status: GetEventSubscriptionStatus): void {
    for (const subscriber of subscribers) {
      if (subscriber.id === id) { continue; }
      const counter = updateLastStatusCount(subscriber.id, status);
      void subscriber.onStatus(counter.status, counter.count);
    }
  }

  function unsubscribe(id: GetEventStatusID): void {
    fireStatus(id, GetEventSubscriptionStatus.Unsubscribe);
    subscribers = subscribers.filter((subscriber) => subscriber.id !== id);
  }

  function unsubscribeAllExcept(id: GetEventStatusID): void {
    fireStatusAllExcept(id, GetEventSubscriptionStatus.Unsubscribe);
    subscribers = subscribers.filter((subscriber) => subscriber.id === id);
  }

  return {
    getSubscribersIds,
    subscribe,
    unsubscribe,
    unsubscribeAllExcept,
    fireStatus,
  };
}
