import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type {
  Sport,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import { useSportlineEventDateTime } from 'web/src/modules/sportline/composables/sportline-event';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

interface UseLiveInfoHeadlineProps {
  sportlineEvent: Ref<SportlineEvent>;
  sport: Ref<Sport>;
}

interface UseLiveInfoHeadlineComposable {
  isTimeReversedIndicatorAvailable: Ref<boolean>;
  hasLogos: Ref<boolean>;
  date: Ref<Maybe<string>>;
  time: Ref<Maybe<string>>;
}

export function useLiveInfoHeadline(
  props: UseLiveInfoHeadlineProps,
): UseLiveInfoHeadlineComposable {
  const { sportlineEvent } = props;

  const settingsStore = useSportlineSettingsStore();
  const isTimeReversedIndicatorAvailable = toRef(settingsStore, 'isTimeReversedIndicatorAvailable');

  const { date, time } = useSportlineEventDateTime({ sportlineEvent });

  const hasLogos = computed<boolean>(() => (
    !!sportlineEvent.value.competitors[0]?.logo
    && !!sportlineEvent.value.competitors[1]?.logo
  ));

  return {
    isTimeReversedIndicatorAvailable,
    hasLogos,
    date,
    time,
  };
}
