import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import { safeParseInt } from '@leon-hub/utils';

// composables
import { useSportlinePageIdentifier } from 'web/src/modules/sportline/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineTreeElementForPageIdentifier } from 'web/src/modules/sportline/submodules/redirects/composables';
// types
import type { LoadStatisticIdentifier } from 'web/src/modules/sportline/submodules/event-statistic/types';
import type { CoreSportEventResponse } from 'web/src/modules/sportline/types/rest';
// enums
import { SportSegmentId } from 'web/src/modules/sportline/enums';

import useSportlineLeagueStandingsStatisticsStoreComposable from './composables/useSportlineLeagueStandingsStatisticsStoreComposable';
import useSportlineMatchStatisticsStoreComposable from './composables/useSportlineMatchStatisticsStoreComposable';
import { useSportlinePostMatchStatisticsStoreComposable } from './composables/useSportlinePostMatchStatisticsStoreComposable';

export const useSportlineEventStatisticStore = defineStore('sportline-event-statistic', () => {
  const sportlineSettingsStore = useSportlineSettingsStore();
  const isSportEventStatisticEnabled = toRef(() => sportlineSettingsStore.isSportEventStatisticEnabled);

  const {
    pageIdentifier,
    setPageIdentifier,
  } = useSportlinePageIdentifier();

  const {
    initialRequests: initSportlineTree,
    sportElement: currentRouteSportElement,
  } = useSportlineTreeElementForPageIdentifier({ pageIdentifier });

  const currentLoadedMatchIdentifier = ref<Maybe<false | LoadStatisticIdentifier>>(null);
  const isBackgroundRequestPriorityIncreased = ref(false);

  // remove false from the value
  const internalIdentifier = computed<Maybe<LoadStatisticIdentifier>>(() => (
    currentLoadedMatchIdentifier.value ? currentLoadedMatchIdentifier.value : null
  ));
  const matchId = computed<Optional<string>>(() => (internalIdentifier.value?.id?.toString()));
  const matchBetline = computed<Optional<string>>(() => (internalIdentifier.value?.betline));
  const matchStatus = computed<Optional<string>>(() => (internalIdentifier.value?.status));

  const setIsBackgroundRequestPriorityIncreased = function setIsBackgroundRequestPriorityIncreased(
    value: boolean,
  ): void {
    isBackgroundRequestPriorityIncreased.value = value;
  };
  const sportlineEventStatisticUpdateInterval = computed<number>(
    () => (isBackgroundRequestPriorityIncreased.value ? 20_000 : 60_000),
  );
  const expectedStatisticId = computed<Maybe<number>>(() => {
    const eventId = pageIdentifier.value.statisticId
      || pageIdentifier.value.sportEventId;

    return eventId !== undefined
      ? safeParseInt(eventId)
      : null;
  });

  const {
    betlineMatchStatistics,
    hasMatchStatistic,
    setBetlineMatchStatistics,
    fetchH2hMatchStatistics,
  } = useSportlineMatchStatisticsStoreComposable({
    expectedStatisticId,
  });
  const {
    betlinePostMatchStatistics,
    postMatchStatistics,
    hasPostMatchStatistics,
    isPostMatchStatisticLoaded,
    confrontationBlocks,
    setBetlinePostMatchStatistics,
    initialRequests: initialPostMatchRequests,
    onInit: onPostMatchInit,
    syncBackgroundRequests: syncPostMatchBackgroundRequests,
    setBackgroundUpdateEnabled: setPostMatchBackgroundUpdateEnabled,
  } = useSportlinePostMatchStatisticsStoreComposable({
    isSportEventStatisticEnabled,
    expectedStatisticId,
    currentLoadedMatchIdentifier,
    sportlineEventStatisticUpdateInterval,
    isBackgroundRequestPriorityIncreased,
    setIsBackgroundRequestPriorityIncreased,
  });
  const {
    betlineLeagueStandings,
    setBetlineLeagueStandings,
    initialRequests: initialLeagueStandingsRequests,
    onInit: onLeagueStandingsInit,
    syncBackgroundRequests: syncLeagueStandingsBackgroundRequests,
    setBackgroundUpdateEnabled: setLeagueStandingsBackgroundUpdateEnabled,
  } = useSportlineLeagueStandingsStatisticsStoreComposable({
    isSportEventStatisticEnabled,
    expectedStatisticId,
    currentLoadedMatchIdentifier,
    sportlineEventStatisticUpdateInterval,
  });
  const statisticSportFamily = computed<Maybe<string>>(
    () => (
      // eslint-disable-next-line ts/prefer-nullish-coalescing
      (postMatchStatistics.value || null)?.family?.toLowerCase()
      ?? betlineMatchStatistics.value?.family?.toLowerCase()
      ?? null
    ),
  );

  function setCurrentLoadedMatchIdentifier(response: Maybe<false | CoreSportEventResponse>): void {
    currentLoadedMatchIdentifier.value = response
      ? {
          id: response.id,
          status: response.status,
          betline: response.betline,
        }
      : response;
  }

  function clearStatistic(): void {
    setCurrentLoadedMatchIdentifier(null);
    setBetlinePostMatchStatistics(null);
    setBetlineLeagueStandings(null);
    setBetlineMatchStatistics(null);
  }

  function syncBackgroundRequests(): void {
    void syncPostMatchBackgroundRequests({ silent: true });
    void syncLeagueStandingsBackgroundRequests({ silent: true });
  }

  function setBackgroundUpdateEnabled(value: boolean): void {
    void setPostMatchBackgroundUpdateEnabled(value);
    void setLeagueStandingsBackgroundUpdateEnabled(value);
  }

  async function allStatisticsSettled(): Promise<void> {
    if (currentRouteSportElement.value?.sport.segment.id === SportSegmentId.CyberSport) {
      // @see LEONWEB-15195 Don't load scores statistics for e-sport
      return;
    }

    // just try to load all statistic because we have only id and don't know status yet
    await Promise.allSettled([
      initialPostMatchRequests(),
      fetchH2hMatchStatistics({ silent: true }),
      initialLeagueStandingsRequests(),
    ]);
  }

  async function initialRequests(): Promise<void> {
    if (!isSportEventStatisticEnabled.value) {
      return;
    }

    try {
      await initSportlineTree();
    } catch {
      // empty, we will try to load statistic for unknown sport anyway
    }

    await allStatisticsSettled();
  }

  function startLoadingPostMatchStatistics(): void {
    setIsBackgroundRequestPriorityIncreased(true);
    void initialPostMatchRequests();
  }

  // on init
  onPostMatchInit();
  onLeagueStandingsInit();

  watch([matchId, matchBetline, matchStatus], (
    [toId, toBetline, toStatus]: [Optional<string>, Optional<string>, Optional<string>],
    [fromId, fromBetline, fromStatus]: [Optional<string>, Optional<string>, Optional<string>],
  ): void => {
    if (toId !== fromId) {
      return;
    }

    if (fromBetline === toBetline && fromStatus === toStatus) {
      return;
    }

    syncBackgroundRequests();
  });

  return {
    isSportEventStatisticEnabled,
    pageIdentifier,
    currentLoadedMatchIdentifier,
    expectedStatisticId,
    hasMatchStatistic,
    hasPostMatchStatistics,
    isPostMatchStatisticLoaded,
    sportlineEventStatisticUpdateInterval,
    betlinePostMatchStatistics,
    postMatchStatistics,
    betlineMatchStatistics,
    betlineLeagueStandings,
    confrontationBlocks,
    statisticSportFamily,
    setPageIdentifier,
    setCurrentLoadedMatchIdentifier,
    setBetlinePostMatchStatistics,
    setBetlineLeagueStandings,
    clearStatistic,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    setIsBackgroundRequestPriorityIncreased,
    startLoadingPostMatchStatistics,
  };
});
