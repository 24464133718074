import type { Ref } from 'vue';
import { computed } from 'vue';

import type { TeamPlayTotalStatistic } from 'web/src/modules/sportline/submodules/core-statistic/types';
import type {
  BetlineMatchStatisticsMatch,
  BetlineSportEventMatchStatistics,
} from 'web/src/modules/sportline/types/rest';
import { calculateTeamPlayStatistic } from 'web/src/modules/sportline/utils/rest/statistic';

interface H2hMatchStatisticProps {
  betlineMatchStatistics: Ref<Maybe<BetlineSportEventMatchStatistics>>;
}

interface H2hMatchStatisticComposable {
  h2hMatchStatistic: Ref<Maybe<TeamPlayTotalStatistic>>;
  joinMatches: Ref<BetlineMatchStatisticsMatch[]>;
}

export function useH2hMatchStatistic(
  props: H2hMatchStatisticProps,
): H2hMatchStatisticComposable {
  const h2hMatchStatistic = computed<Maybe<TeamPlayTotalStatistic>>(
    () => calculateTeamPlayStatistic(props.betlineMatchStatistics.value?.scores24Head2Head),
  );
  const joinMatches = computed<BetlineMatchStatisticsMatch[]>(
    () => [...(props.betlineMatchStatistics.value?.joinMatches ?? [])],
  );

  return {
    h2hMatchStatistic,
    joinMatches,
  };
}
