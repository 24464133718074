import { toRef } from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';
import { useI18nStore } from '@core/i18n';

import type { MetrikaHeadlineNavigationType } from 'web/src/modules/sportline/submodules/event-details/enums';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type { Market } from 'web/src/modules/sportline/types';
import type { SportEventMetric } from 'web/src/modules/sportline/types/analytics';
import type { SportlinePostMatchStatistics } from 'web/src/modules/sportline/types/statistics';
import { SportEventMatchPhase } from 'web/src/modules/sportline/enums';
import {
  visitSportEvent,
} from 'web/src/modules/sportline/submodules/event-details/composables/analytics/utils/visitSportEvent';
import { MetrikaCategory, MetrikaEvents } from 'web/src/modules/sportline/submodules/event-details/enums';

interface UseSportlineEventDetailsHeadlineAnalyticsComposable {
  sendCarouselEvent(event: string): void;
  sendChangeSlideEvent(event: string): void;
  sendAvailableButtonsNavigation(type: MetrikaHeadlineNavigationType): void;
  sendSelectButtonsNavigation(type: MetrikaHeadlineNavigationType): void;
}

export interface UseSportlineEventDetailsAnalyticsComposable {
  sendVisitPageAnalytics(sportlineEvent: SportEventDetails): void;
  sendVisitStatisticsPageAnalytics(statistics: SportlinePostMatchStatistics): void;
}

export type SendVisitPagePayload = SportEventMetric & { markets: Market[] };

export function getVisitPagePayload({ sportlineEvent, language }: {
  sportlineEvent: SportEventDetails;
  language: string;
}): Maybe<SendVisitPagePayload> {
  // @TODO check analytics for live outright
  if (sportlineEvent.isOutright) {
    return null;
  }

  const { sport } = sportlineEvent;

  return {
    eventId: sportlineEvent.id,
    home: sportlineEvent.competitors[0]?.name || '',
    homeLogo: sportlineEvent.competitors[0]?.logo,
    away: sportlineEvent.competitors[1]?.name || '',
    awayLogo: sportlineEvent.competitors[1]?.logo,
    scheduled: new Date(sportlineEvent.kickoff).toISOString(),
    status: sportlineEvent.matchPhase,
    tournamentName: sportlineEvent.league.name,
    sport: sport.family,
    markets: sportlineEvent.markets,
    homeShort: undefined,
    awayShort: undefined,
    oddsHome: undefined,
    oddsDraw: undefined,
    oddsAway: undefined,
    language,
  };
}

export function getVisitStatisticsPagePayload({ statistics, language }: {
  statistics?: Maybe<SportlinePostMatchStatistics>;
  language?: string;
}): Maybe<SendVisitPagePayload> {
  if (!statistics || !language) {
    return null;
  }

  const {
    id: eventId,
    teams,
    matchDate,
    family: sport,
  } = statistics;

  const home = teams[0]?.name;
  const homeLogo = teams[0]?.logo ?? undefined;
  const away = teams[1]?.name;
  const awayLogo = teams[1]?.logo ?? undefined;

  if (
    !eventId
    || !home
    || !away
    || !matchDate
    || !sport
  ) { return null; }

  return {
    eventId,
    home,
    homeLogo,
    away,
    awayLogo,
    scheduled: new Date(matchDate).toISOString(),
    status: SportEventMatchPhase.PostGame,
    tournamentName: '',
    sport,
    markets: [],
    homeShort: undefined,
    awayShort: undefined,
    oddsHome: undefined,
    oddsDraw: undefined,
    oddsAway: undefined,
    language,
  };
}

export function useSportlineEventDetailsAnalytics(): UseSportlineEventDetailsAnalyticsComposable {
  if (process.env.VUE_APP_PRERENDER) {
    return {
      sendVisitPageAnalytics(): void {},
      sendVisitStatisticsPageAnalytics(): void {},
    };
  }

  const lang = toRef(useI18nStore(), 'lang');

  function sendVisitPageAnalytics(sportlineEvent: SportEventDetails): void {
    if (!sportlineEvent) {
      return;
    }

    const payload = getVisitPagePayload({
      sportlineEvent,
      language: lang.value,
    });

    if (payload) {
      visitSportEvent(payload);
    }
  }

  function sendVisitStatisticsPageAnalytics(statistics?: Maybe<SportlinePostMatchStatistics>): void {
    const payload = getVisitStatisticsPagePayload({ statistics, language: lang.value });

    if (payload) {
      visitSportEvent(payload);
    }
  }

  return { sendVisitPageAnalytics, sendVisitStatisticsPageAnalytics };
}

export function useSportlineEventDetailsHeadlineAnalytics(
): UseSportlineEventDetailsHeadlineAnalyticsComposable {
  const { push } = useAnalytics();

  function sendSwiperNavigationAction(payload: object): void {
    push(AnalyticsEvent.USER_ACTIONS, {
      [MetrikaCategory.SWIPER_NAVIGATION]: payload,
    });
  }

  function sendButtonsNavigationAction(payload: object): void {
    push(AnalyticsEvent.USER_ACTIONS, {
      [MetrikaCategory.BUTTONS_NAVIGATION]: payload,
    });
  }

  function sendCarouselEvent(event: string = MetrikaEvents.EVENT_KNOBS): void {
    sendSwiperNavigationAction({
      [MetrikaEvents.EVENTS_CAROUSEL]: event,
      [MetrikaEvents.EVENTS_CHANGE_SLIDE]: event,
    });
  }

  function sendChangeSlideEvent(event: string = MetrikaEvents.EVENT_KNOBS): void {
    sendSwiperNavigationAction({ [MetrikaEvents.EVENTS_CHANGE_SLIDE]: event });
  }

  function sendAvailableButtonsNavigation(type: MetrikaHeadlineNavigationType): void {
    sendButtonsNavigationAction({
      [MetrikaEvents.EVENTS_IS_AVAILABLE]: type,
    });
  }

  function sendSelectButtonsNavigation(type: MetrikaHeadlineNavigationType): void {
    sendButtonsNavigationAction({
      [MetrikaEvents.EVENTS_CLICKS]: type,
    });
  }

  return {
    sendCarouselEvent,
    sendChangeSlideEvent,
    sendAvailableButtonsNavigation,
    sendSelectButtonsNavigation,
  };
}
