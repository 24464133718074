import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

// composables
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineEventLiveWidget } from 'web/src/modules/sportline/submodules/event-details/composables/widget';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
// types
import type {
  SportEventWidgetConfig,
  SportlineEventDetails,
} from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  LiveWidget,
  SportlineWidgetType,
} from 'web/src/modules/sportline/types';
// constants
import {
  sportlineWidgetTypeBetgenius,
  sportlineWidgetTypeLsports,
  sportlineWidgetTypeOddin,
  sportlineWidgetTypeSportradar,
} from 'web/src/modules/sportline/constants/widgets';

import {
  isBetgeniusWidgetAvailable,
  isOddinWidgetAvailable,
  isSportradarLiveMatchTrackerAvailable,
} from '../utils/widgets';

interface UseSportEventWidgetConfigProps {
  sportEventDetails: Ref<Maybe<SportlineEventDetails>>;
}

interface UseSportEventWidgetConfigComposable {
  sportEventWidgetConfig: Ref<SportEventWidgetConfig>;
  liveMatchTrackerWidget: Ref<Maybe<LiveWidget>>;
  liveWidgetType: Ref<Optional<SportlineWidgetType>>;
}

export function useSportEventWidgetConfig(
  props: UseSportEventWidgetConfigProps,
): UseSportEventWidgetConfigComposable {
  const { sportEventDetails } = props;

  const sportlineWidgetsStore = useSportlineWidgetsStore();
  const betgeniusConfigFinal = toRef(() => sportlineWidgetsStore.betgeniusConfigFinal);
  const lsportsConfigFinal = toRef(() => sportlineWidgetsStore.lsportsConfigFinal);
  const { isActiveLSportWidgetSport } = sportlineWidgetsStore;

  const sportlineSettingsStore = useSportlineSettingsStore();
  const isLiveStreamEnabled = toRef(() => sportlineSettingsStore.isLiveStreamEnabled);
  const isSportradarLiveMatchTrackerEnabled = toRef(() => sportlineSettingsStore.isSportradarLiveMatchTrackerEnabled);
  const isBetGeniusWidgetEnabled = toRef(() => sportlineSettingsStore.isBetGeniusWidgetEnabled);
  const isLSportsWidgetEnabled = toRef(() => sportlineSettingsStore.isLSportsWidgetEnabled);
  const isLiveStreamWidgetFloatingEnabled = toRef(() => sportlineSettingsStore.isLiveStreamWidgetFloatingEnabled);
  const isSportRadarWidgetFloatingEnabled = toRef(() => sportlineSettingsStore.isSportRadarWidgetFloatingEnabled);

  const { liveWidget: liveMatchTrackerWidget } = useSportlineEventLiveWidget({ sportlineEvent: sportEventDetails });

  /** @deprecated use each setting directly */
  const sportEventWidgetConfig = computed<SportEventWidgetConfig>(() => ({
    liveStreamEnabled: isLiveStreamEnabled.value,
    sportradarLiveMatchTrackerEnabled: isSportradarLiveMatchTrackerEnabled.value,
    betGeniusWidgetEnabled: isBetGeniusWidgetEnabled.value,
    lsportsWidgetEnabled: isLSportsWidgetEnabled.value,
    liveStreamWidgetFloatingEnabled: isLiveStreamWidgetFloatingEnabled.value,
    sportRadarWidgetFloatingEnabled: isSportRadarWidgetFloatingEnabled.value,
  }));

  const isLSportsWidgetAvailable = computed<boolean>(() => {
    if (
      !sportEventDetails.value
      || !lsportsConfigFinal.value?.active
      || !isLSportsWidgetEnabled.value
      || liveMatchTrackerWidget.value?.type !== sportlineWidgetTypeLsports
    ) { return false; }

    return isActiveLSportWidgetSport(
      // @TODO check is this really sportFamily from representation or must be from navigationParameters
      sportEventDetails.value.sport.representation.family,
    ).value;
  });

  const liveWidgetType = computed<Optional<SportlineWidgetType>>(() => {
    if (!liveMatchTrackerWidget.value) {
      return undefined;
    }

    if (isBetgeniusWidgetAvailable(
      liveMatchTrackerWidget.value,
      isBetGeniusWidgetEnabled.value,
      !!betgeniusConfigFinal.value?.active,
    )) {
      return sportlineWidgetTypeBetgenius;
    }

    if (isLSportsWidgetAvailable.value) {
      return sportlineWidgetTypeLsports;
    }

    if (isSportradarLiveMatchTrackerAvailable(
      liveMatchTrackerWidget.value,
      isSportradarLiveMatchTrackerEnabled.value,
    )) {
      return sportlineWidgetTypeSportradar;
    }

    if (
      process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED
      && isOddinWidgetAvailable(liveMatchTrackerWidget.value)
    ) {
      return sportlineWidgetTypeOddin;
    }

    return undefined;
  });

  return {
    sportEventWidgetConfig,
    liveMatchTrackerWidget,
    liveWidgetType,
  };
}
