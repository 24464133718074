import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useGoogleAnalytics } from '@core/analytics';

import type { Market } from 'web/src/modules/sportline/types';
import type { SportEventMetric, SportEventOptions } from 'web/src/modules/sportline/types/analytics';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function visitSportEvent(options: SportEventMetric & { markets: Market[] }): void {
  const { pushGTM } = useGoogleAnalytics();
  const { markets, ...parameters } = options;
  const primaryMarket = markets.find((market) => market.isPrimary);
  if (primaryMarket) {
    const { runners } = primaryMarket;
    const isDrawPossible = runners.length > 2;
    for (const [index, runner] of runners.entries()) {
      const { value } = runner;
      if (index === 0) {
        parameters.oddsHome = value;
      }
      if (index === 1) {
        if (isDrawPossible) {
          parameters.oddsDraw = value;
        } else {
          parameters.oddsAway = value;
        }
      }
      if (index === 2) {
        parameters.oddsAway = value;
      }
    }
  }

  const sportEventOptions: SportEventOptions = {
    match_id: parameters.eventId,
    sr_match_id: '',
    team_home_name: parameters.home,
    team_home_name_short: parameters.homeShort || '',
    team_home_logo: parameters.homeLogo || '',
    team_away_name: parameters.away,
    team_away_name_short: parameters.awayShort || '',
    team_away_logo: parameters.awayLogo || '',
    scheduled: parameters.scheduled,
    status: parameters.status,
    tournament_name: parameters.tournamentName,
    sport: parameters.sport,
    odds_home: parameters.oddsHome || '',
    odds_draw: parameters.oddsDraw || '',
    odds_away: parameters.oddsAway || '',
    language: parameters.language,
  };

  pushGTM(AnalyticsEvent.SPORT_EVENT, { sportEvent: sportEventOptions });
}
