import type { LiveWidget } from 'web/src/modules/sportline/types';
import {
  sportlineWidgetTypeBetgenius,
  sportlineWidgetTypeOddin,
  sportlineWidgetTypeSportradar,
} from 'web/src/modules/sportline/constants/widgets';

export function isSportradarLiveMatchTrackerAvailable(
  liveWidgetType: Maybe<LiveWidget>,
  isEnabled: boolean,
): boolean {
  return liveWidgetType?.type === sportlineWidgetTypeSportradar
    && isEnabled;
}

export function isBetgeniusWidgetAvailable(
  liveWidgetType: Maybe<LiveWidget>,
  isEnabled: boolean,
  isActive: boolean,
): boolean {
  return liveWidgetType?.type === sportlineWidgetTypeBetgenius
    && isEnabled
    && isActive;
}

export function isOddinWidgetAvailable(
  liveWidgetType: Maybe<LiveWidget>,
): boolean {
  return !!process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED
    && liveWidgetType?.type === sportlineWidgetTypeOddin;
}
