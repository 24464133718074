import { normalizeError } from '@leon-hub/errors';

import type { UseErrorsConverterComposable } from '@core/errors';
import { useErrorsConverter } from '@core/errors';
import { useI18n } from '@core/i18n';

import { StreamError } from 'web/src/modules/sportline/submodules/streams/errors';

interface UseStreamErrorsConverterComposable extends UseErrorsConverterComposable {
  convertToStreamError(error: unknown, message?: string): StreamError;
}

export function useStreamErrorsConverter(): UseStreamErrorsConverterComposable {
  const { convertToBaseError } = useErrorsConverter();

  const { $translate } = useI18n();
  const defaultTitle = $translate('WEB2_STREAM_UNAVAILABLE_ERROR');

  function convertToStreamError(error: unknown, message?: string): StreamError {
    return new StreamError({
      defaultTitle: defaultTitle.value,
      originalError: normalizeError(error, message),
    });
  }

  return { convertToStreamError, convertToBaseError };
}
