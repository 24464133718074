import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import type { Payloads } from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

type SetBackgroundRequestsMethod<Treads extends string[] = []> = (value: boolean, payloads?: Payloads<Treads>) => Promise<void>;

interface CreateWatchBackgroundRequestsConditionComposable<Treads extends string[] = []> {
  setBackgroundUpdateEnabled: SetBackgroundRequestsMethod<Treads>;
}

export function createWatchBackgroundRequestsCondition<Treads extends string[] = []>(
  condition: Ref<boolean>,
  originalSetBackgroundUpdateEnabled: SetBackgroundRequestsMethod<Treads>,
): CreateWatchBackgroundRequestsConditionComposable<Treads> {
  const isBackgroundUpdateEnabled = ref(false);

  const isBackgroundUpdateEnabledFinal = computed<boolean>(() => (condition.value && isBackgroundUpdateEnabled.value));

  watch(isBackgroundUpdateEnabledFinal, (value) => {
    void originalSetBackgroundUpdateEnabled(value);
  }, { immediate: true });

  return {
    setBackgroundUpdateEnabled(value: boolean): Promise<void> {
      isBackgroundUpdateEnabled.value = value;
      return Promise.resolve();
    },
  };
}
