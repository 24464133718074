import type { Ref } from 'vue';

import type { ITheme } from '@leon-hub/api-sdk';

import type { GetEventStatusID } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/types';
import type { CoreSportEventResponse, CoreSportlineFetchOptions } from 'web/src/modules/sportline/types/rest';
import type { SportlineApiService } from 'web/src/modules/sportline/types/services';
import { GetEventSubscriptionStatus } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
import { isWidgetDataReceived, normalizeWidgetIFrameUrls } from 'web/src/modules/sportline/utils/rest/widgets';

export type FetchDetailsMethodOptions = CoreSportlineFetchOptions & {
  eventId?: string;
  theme: ITheme;
};

export type FetchDetailsMethod = (options: FetchDetailsMethodOptions) => Promise<Maybe<CoreSportEventResponse>>;

export function createFetchDetailsMethod(apiService: SportlineApiService, props: {
  currentResponse: Ref<Maybe<CoreSportEventResponse | false>>;
  loadedId: Ref<Maybe<string>>;
  fireRequestStatus(id: GetEventStatusID, status: GetEventSubscriptionStatus): void;
  beforeRequest?(): void;
  onMissingEvent?(eventId: string): void;
}): FetchDetailsMethod {
  const {
    currentResponse,
    loadedId,
    fireRequestStatus,
    beforeRequest,
    onMissingEvent,
  } = props;

  let lastLoadedTheme: Maybe<ITheme> = null;
  let awaitForWidgetData: boolean = false;

  return async function fetchDetails(options: FetchDetailsMethodOptions): Promise<Maybe<CoreSportEventResponse>> {
    const {
      silent,
      eventId,
      theme: currentTheme,
    } = options;

    if (!eventId) {
      return null;
    }

    beforeRequest?.();

    let result: Maybe<CoreSportEventResponse>;

    try {
      let doAddWidgetFlags: Optional<boolean>;
      let themeFlag: Optional<ITheme>;

      if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED) {
        const isFirstRequest = loadedId.value !== eventId;
        const isThemeChanged = lastLoadedTheme !== currentTheme;

        if (awaitForWidgetData || isFirstRequest || isThemeChanged) {
          doAddWidgetFlags = true;
          themeFlag = currentTheme;
          awaitForWidgetData = true;
        }
      }

      result = await apiService.getEventDetails({
        id: eventId,
        silent,
        doAddWidgetFlags,
        themeFlag,
      });
    } catch (error) {
      fireRequestStatus(eventId, GetEventSubscriptionStatus.Error);
      throw error;
    }

    if (!result) {
      fireRequestStatus(eventId, GetEventSubscriptionStatus.Missing);
      onMissingEvent?.(eventId);
      return null;
    }

    const resultId = String(result.id);

    fireRequestStatus(resultId, GetEventSubscriptionStatus.OK);

    // old data
    if (eventId !== resultId) {
      return null;
    }

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED && awaitForWidgetData && isWidgetDataReceived(result)) {
      // @see LEONWEB-15463 Keep this flag true only if we haven't received widget data to send flag on the next request
      awaitForWidgetData = false;
    }

    lastLoadedTheme = currentTheme;

    // eslint-disable-next-line ts/prefer-nullish-coalescing
    result = normalizeWidgetIFrameUrls(result, currentResponse.value || null, { doKeepOldData: true });

    return result;
  };
}
