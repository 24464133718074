import type { Ref } from 'vue';

import type { SportlineEventId } from 'web/src/modules/sportline/types';
import type { SportlinePageIdentifier } from 'web/src/modules/sportline/types/navigation';

interface CreateStartInitialRequestsMethodProps {
  sportlineEventId: Ref<SportlineEventId | null>;
  pageIdentifier: Ref<SportlinePageIdentifier>;
  loadEventData(): Promise<void>;
  loadStatistics(): Promise<void>;
}

export function createStartInitialRequestsMethod(
  props: CreateStartInitialRequestsMethodProps,
): () => Promise<void> {
  const {
    sportlineEventId,
    pageIdentifier,
    loadEventData,
    loadStatistics,
  } = props;

  return async function startInitialRequests(): Promise<void> {
    if (sportlineEventId.value && sportlineEventId.value === pageIdentifier.value.sportEventId) {
      // this event already loaded, will be used sync background
      return;
    }

    await Promise.allSettled([
      loadEventData(),
      loadStatistics(),
    ]);
  };
}
