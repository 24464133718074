import { type Ref, toRef } from 'vue';

// composables
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
// types
import type { CoreSportEventResponse } from 'web/src/modules/sportline/types/rest';
import type { SportlinePostMatchStatistics } from 'web/src/modules/sportline/types/statistics';

interface UseScoresStatisticsStoreComposable {
  postMatchStatistics: Ref<Maybe<SportlinePostMatchStatistics> | false>;
  isSportEventStatisticEnabled: Ref<boolean>;
  hasPostMatchStatistics: Ref<boolean>;
  isPostMatchStatisticLoaded: Ref<boolean>;
  initialStatisticsRequests(): Promise<void>;
  setStatisticsBackgroundUpdateEnabled(value: boolean): void;
  startLoadingPostMatchStatistics(): void;
  setCurrentLoadedMatchIdentifier(response: Maybe<false | CoreSportEventResponse>): void;
  clearMatchStatistics(): void;
}

export function useScoresStatisticsStoreComposable(): UseScoresStatisticsStoreComposable {
  const sportlineEventStatisticStore = useSportlineEventStatisticStore();

  const isSportEventStatisticEnabled = toRef(() => sportlineEventStatisticStore.isSportEventStatisticEnabled);
  const hasPostMatchStatistics = toRef(() => sportlineEventStatisticStore.hasPostMatchStatistics);
  const isPostMatchStatisticLoaded = toRef(() => sportlineEventStatisticStore.isPostMatchStatisticLoaded);
  const postMatchStatistics = toRef(() => sportlineEventStatisticStore.postMatchStatistics);

  const {
    initialRequests: initialStatisticsRequests,
    setBackgroundUpdateEnabled: setStatisticsBackgroundUpdateEnabled,
    startLoadingPostMatchStatistics,
    setCurrentLoadedMatchIdentifier,
    clearStatistic: clearMatchStatistics,
  } = sportlineEventStatisticStore;

  return {
    isSportEventStatisticEnabled,
    hasPostMatchStatistics,
    isPostMatchStatisticLoaded,
    postMatchStatistics,
    initialStatisticsRequests,
    setStatisticsBackgroundUpdateEnabled,
    startLoadingPostMatchStatistics,
    setCurrentLoadedMatchIdentifier,
    clearMatchStatistics,
  };
}
