/* eslint-disable ts/no-redeclare */

export const GetEventSubscriptionStatus = {
  None: 'None',
  OK: 'OK',
  Missing: 'Missing',
  Error: 'Error',
  Unsubscribe: 'Unsubscribe',
} as const;

export type GetEventSubscriptionStatus = typeof GetEventSubscriptionStatus[keyof typeof GetEventSubscriptionStatus];

export const GetEventSubscriptionAction = {
  Finish: 'Finish',
  AwaitNext: 'AwaitNext',
  Redirect: 'Redirect',
  ShowError: 'ShowError',
  Unsubscribe: 'Unsubscribe',
} as const;

export type GetEventSubscriptionAction = typeof GetEventSubscriptionAction[keyof typeof GetEventSubscriptionAction];
