import type { CoreSportlineEventWidget } from 'web/src/modules/sportline/types/rest';
import type { CheckMaybe } from 'web/src/modules/sportline/types/utils';
import { betlineWidgetTypeOddin } from 'web/src/modules/sportline/constants/rest';

export function isEqualIFrameUrlExceptTimestamp(url?: string, oldUrl?: string): boolean {
  if (!url || !oldUrl) {
    return false;
  }

  try {
    const parsed = new URL(url);
    const parsedOld = new URL(oldUrl);

    parsed.searchParams.delete('t');
    parsedOld.searchParams.delete('t');

    return parsed.toString() === parsedOld.toString();
  } catch {
    return false;
  }
}

export function isWidgetDataReceived(response?: Maybe<CoreSportlineEventWidget>): boolean {
  if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED && response?.widgetType === betlineWidgetTypeOddin) {
    return !!response?.widgetData;
  }

  return true;
}

export function normalizeWidgetIFrameUrls<Response extends Maybe<CoreSportlineEventWidget>>(
  response: CheckMaybe<Response, Response>,
  oldResponse: Response | null,
  options?: { doKeepOldData?: boolean },
): CheckMaybe<Response, Response> {
  const widgetData = response?.widgetData;
  const oldWidgetData = oldResponse?.widgetData;

  if (!widgetData) {
    if (
      process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED
      && options?.doKeepOldData
      && oldResponse && oldWidgetData
      && oldResponse.widgetType === betlineWidgetTypeOddin
    ) {
      return {
        ...oldResponse,
        widgetId: oldResponse?.widgetId,
        widgetType: oldResponse?.widgetType,
        widgetData: { ...oldWidgetData },
      };
    }

    return response;
  }

  if (!oldWidgetData) {
    return response;
  }

  if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDIN_WIDGETS_ENABLED && response && response.widgetType === betlineWidgetTypeOddin) {
    const result = { ...response };

    // keep old link if only t query param is different
    if (isEqualIFrameUrlExceptTimestamp(widgetData.prematchIframe, oldWidgetData.prematchIframe)) {
      result.widgetData!.prematchIframe = oldWidgetData.prematchIframe;
    }

    if (isEqualIFrameUrlExceptTimestamp(widgetData.liveIframe, oldWidgetData.liveIframe)) {
      result.widgetData!.liveIframe = oldWidgetData.liveIframe;
    }

    return { ...response };
  }

  return response;
}
