import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Web2Image } from '@leon-hub/api-sdk';
import { DateTime } from '@leon-hub/utils';

import type { BetlineLeagueRepresentationData } from 'web/src/modules/sportline/submodules/core-statistic/types';
import type { Sport, SportlineEvent } from 'web/src/modules/sportline/types';
import { useSportlineEventChampionshipName } from 'web/src/modules/sportline/composables/sportline-event';
import { useLeagueRepresentation } from 'web/src/modules/sportline/submodules/core-statistic/composables';

export interface UseOutrightInfoHeadlineProps {
  sportlineEvent: Ref<SportlineEvent>;
  leagueRepresentationData: Ref<Maybe<BetlineLeagueRepresentationData>>;
  sport: Ref<Maybe<Sport>>;
}

interface UseOutrightInfoHeadlineComposable {
  backgroundColor: Ref<Maybe<string>>;
  backgroundImage: Ref<Maybe<Web2Image>>;
  championshipName: Ref<Maybe<string>>;
  outrightName: Ref<Maybe<string>>;
  date: Ref<string>;
  time: Ref<string>;
  hasZeroMargin: Ref<boolean>;
}

export function useOutrightInfoHeadline(
  props: UseOutrightInfoHeadlineProps,
): UseOutrightInfoHeadlineComposable {
  const {
    sportlineEvent,
    sport,
    leagueRepresentationData,
  } = props;

  const {
    backgroundImage,
    backgroundColor,
  } = useLeagueRepresentation({ sport, leagueRepresentationData });
  const { championshipName, outrightName } = useSportlineEventChampionshipName({ sportlineEvent });

  const date = computed<string>(() => DateTime
    .withTimeStamp(sportlineEvent.value.kickoff)
    .toDate());
  const time = computed<string>(() => DateTime
    .withTimeStamp(sportlineEvent.value.kickoff)
    .toTime());
  const hasZeroMargin = computed<boolean>(() => sportlineEvent.value.hasZeroMargin);

  return {
    backgroundImage,
    backgroundColor,
    championshipName,
    outrightName,
    date,
    time,
    hasZeroMargin,
  };
}
