import type { TeamPlayTotalStatistic } from 'web/src/modules/sportline/submodules/core-statistic/types';
import type {
  BetlineSportEventMatchStatistics,
} from 'web/src/modules/sportline/types/rest';

export function calculateTeamPlayStatistic(
  statistics?: Maybe<BetlineSportEventMatchStatistics['scores24Head2Head']>,
): Maybe<TeamPlayTotalStatistic> {
  const totalStatistic: TeamPlayTotalStatistic = {
    totalGames: statistics?.total || 0,
    draws: statistics?.draws || 0,
    drawsPercentage: 0,
    host: {
      wins: statistics?.wins[0] || 0,
      winsPercentage: 0,
      goals: statistics?.goals[0] || 0,
    },
    guest: {
      wins: statistics?.wins[1] || 0,
      winsPercentage: 0,
      goals: statistics?.goals[1] || 0,
    },
  };

  if (totalStatistic.totalGames === 0) {
    return null;
  }

  // calculate percentages
  totalStatistic.drawsPercentage = (100 / totalStatistic.totalGames) * totalStatistic.draws;
  totalStatistic.host.winsPercentage = (100 / totalStatistic.totalGames) * totalStatistic.host.wins;
  totalStatistic.guest.winsPercentage = (100 / totalStatistic.totalGames) * totalStatistic.guest.wins;
  // round to 1 digit
  totalStatistic.drawsPercentage = Math.round(totalStatistic.drawsPercentage * 10) / 10;
  totalStatistic.host.winsPercentage = Math.round(totalStatistic.host.winsPercentage * 10) / 10;
  totalStatistic.guest.winsPercentage = Math.round(totalStatistic.guest.winsPercentage * 10) / 10;

  return totalStatistic;
}
