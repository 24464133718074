import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  LiveWidget,
  SportlineEventWidgetInfo,
  SportlineWidgetType,
} from 'web/src/modules/sportline/types';

interface UseSportlineEventLiveWidgetProps {
  // eslint-disable-next-line ts/no-empty-object-type
  sportlineEvent: Ref<Maybe<SportlineEventWidgetInfo | {} | undefined>>;
}

interface UseSportlineEventLiveWidgetComposable {
  liveWidget: Ref<Maybe<LiveWidget>>;
  liveWidgetType: Ref<Optional<SportlineWidgetType>>;
}

export function useSportlineEventLiveWidget(
  props: UseSportlineEventLiveWidgetProps,
): UseSportlineEventLiveWidgetComposable {
  const { sportlineEvent } = props;

  const liveWidget = computed<Maybe<LiveWidget>>(() => (
    sportlineEvent.value && 'liveWidget' in sportlineEvent.value
      ? (sportlineEvent.value?.liveWidget ?? null)
      : null
  ));

  return {
    liveWidget,
    liveWidgetType: computed(() => liveWidget.value?.type as Optional<SportlineWidgetType>),
  };
}
