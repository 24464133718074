import type { Ref } from 'vue';

import type { SportlineEventDetails } from 'web/src/modules/sportline/types/details';
import type { SportlinePostMatchStatistics } from 'web/src/modules/sportline/types/statistics';

import { useSportlineEventDetailsAnalytics } from '../../composables/analytics';

interface CreateSendVisitPageAnalyticsMethodProps {
  sportlineEvent: Ref<Maybe<SportlineEventDetails>>;
  postMatchStatistics: Ref<Maybe<SportlinePostMatchStatistics> | false>;
}

export function createSendVisitPageAnalyticsMethod(
  props: CreateSendVisitPageAnalyticsMethodProps,
  analytics = useSportlineEventDetailsAnalytics(),
): () => void {
  const { sportlineEvent, postMatchStatistics } = props;

  return function sendVisitPageAnalytics(): void {
    if (sportlineEvent.value) {
      analytics.sendVisitPageAnalytics(sportlineEvent.value);
      return;
    }

    if (postMatchStatistics.value) {
      analytics.sendVisitStatisticsPageAnalytics(postMatchStatistics.value);
    }
  };
}
