import type {
  GetEventSubscriptionAction,
  GetEventSubscriptionStatus,
} from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
import type {
  GetEventStatusID,
} from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/types';
import {
  createGetEventFailedRequestsCounter,
  createGetEventRequestOnInitBehaviour,
  createGetEventRequestOnPageBehaviour,
} from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter';

export type RequestBehaviourActionsMap = Partial<Record<GetEventSubscriptionAction, () => void>>;

type CallAction = (action: GetEventSubscriptionAction) => void;

export interface UseSportlineEventDetailsRequestBehavioursComposable {
  addOnPageBehaviour(id: GetEventStatusID, actions: RequestBehaviourActionsMap): void;
  addOnInitBehaviour(id: GetEventStatusID, actions: RequestBehaviourActionsMap): void;
  removeBehaviours(id: GetEventStatusID): void;
  removeBehavioursExcept(id: GetEventStatusID): void;
  fireRequestStatus(id: GetEventStatusID, status: GetEventSubscriptionStatus): void;
}

function createCallActionMethod(actions: RequestBehaviourActionsMap): CallAction {
  return (action: GetEventSubscriptionAction): void => {
    actions[action]?.();
  };
}

/**
 * This manager creates behaviours and connects them with a Status counter.
 * The status counter gets the last fetch status and fire a Status event.
 * The behaviours get a Status event and produce a Subscription event.
 * The page will act based on the last Subscription event.
 */
export function useSportlineEventDetailsRequestStatusBehaviours(): UseSportlineEventDetailsRequestBehavioursComposable {
  const failedRequestCounter = createGetEventFailedRequestsCounter();

  function addOnPageBehaviour(id: GetEventStatusID, actions: RequestBehaviourActionsMap): void {
    const behavior = createGetEventRequestOnPageBehaviour(id, createCallActionMethod(actions));
    failedRequestCounter.subscribe(behavior);
  }

  function addOnInitBehaviour(id: GetEventStatusID, actions: RequestBehaviourActionsMap): void {
    const behavior = createGetEventRequestOnInitBehaviour(id, createCallActionMethod(actions));
    failedRequestCounter.subscribe(behavior);
  }

  function removeBehaviours(id: GetEventStatusID): void {
    failedRequestCounter.unsubscribe(id);
  }

  function removeBehavioursExcept(id: GetEventStatusID): void {
    failedRequestCounter.unsubscribeAllExcept(id);
  }

  function fireRequestStatus(id: GetEventStatusID, status: GetEventSubscriptionStatus): void {
    failedRequestCounter.fireStatus(id, status);
  }

  return {
    addOnPageBehaviour,
    addOnInitBehaviour,
    removeBehaviours,
    removeBehavioursExcept,
    fireRequestStatus,
  };
}
