import { safeParseInt } from '@leon-hub/utils';

import type { TeamsConfrontationStatisticBlockData } from 'web/src/modules/sportline/submodules/event-statistic/types';
import type { BetlinePostMatchStatisticsPeriod, BetlinePostMatchStatisticsPeriodItem } from 'web/src/modules/sportline/types/rest';
import { getSafePercentage } from 'web/src/modules/core/utils/math';

function parsePostMatchStatisticPeriodItem(
  item: BetlinePostMatchStatisticsPeriodItem,
): Maybe<TeamsConfrontationStatisticBlockData> {
  const {
    type,
    name,
    firstTeamValue,
    firstTeamPercent,
    firstTeamTotal,
    secondTeamValue,
    secondTeamPercent,
    secondTeamTotal,
  } = item;

  if (
    !name
    || !firstTeamValue
    || !secondTeamValue
  ) { return null; }

  const value1 = safeParseInt(firstTeamValue) ?? 0;
  const value2 = safeParseInt(secondTeamValue) ?? 0;
  const firstTotal = firstTeamTotal ? safeParseInt(firstTeamTotal) : null;
  const secondTotal = secondTeamTotal ? safeParseInt(secondTeamTotal) : null;

  const parsedTotal = value1 + value2;
  const firstPercent = firstTeamPercent
    ? safeParseInt(firstTeamPercent)
    : getSafePercentage(value1, firstTotal ?? parsedTotal);
  const secondPercent = secondTeamPercent
    ? safeParseInt(secondTeamPercent)
    : getSafePercentage(value2, secondTotal ?? parsedTotal);

  return {
    type: type ?? null,
    name,
    host: {
      value: value1.toString(),
      percent: Math.round(firstPercent ?? 0),
      total: (firstTotal ?? parsedTotal).toString(),
    },
    guest: {
      value: value2.toString(),
      percent: Math.round(secondPercent ?? 0),
      total: (secondTotal ?? parsedTotal).toString(),
    },
  };
}

export default function parsePostMatchStatisticConfrontationBlocks(
  periods?: Maybe<BetlinePostMatchStatisticsPeriod[]>,
): TeamsConfrontationStatisticBlockData[] {
  if (!periods) { return []; }

  return periods
    .flatMap((period) => period.groups
      .flatMap((group) => group.items
        .map((item) => parsePostMatchStatisticPeriodItem(item))))
    .filter((block): block is TeamsConfrontationStatisticBlockData => block !== null);
}
