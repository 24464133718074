import { DateTime } from '@leon-hub/utils';

import { useDateLocalizers } from '@core/i18n';

import type { SportEventSeoMetaInfo } from 'web/src/modules/sportline/types';
import type { BetlinePostMatchStatistics } from 'web/src/modules/sportline/types/rest';
import { getSportFamilyEmoji } from 'web/src/modules/sportline/utils';

export function getSportEventDetailsSeoMetaInfoFromPostStatistic(
  statistic?: Maybe<BetlinePostMatchStatistics | false>,
  options?: {
    url?: string;
  },
): SportEventSeoMetaInfo | Record<string, never> {
  if (!statistic) {
    return {};
  }

  const host = statistic.teams?.[0];
  const guest = statistic.teams?.[1];

  if (!host || !guest) {
    return {};
  }

  const sportFamily = statistic.family || '';
  const kickoff = statistic.matchDate;

  return {
    name: `${host.name || ''} - ${guest.name || ''}`,
    kickoff: kickoff ? kickoff.toString() : '',
    time: kickoff ? DateTime.withTimeStamp(kickoff).toTime() : '',
    date: kickoff ? useDateLocalizers().toFullLocaleDate(kickoff) : '',
    startDateISO: kickoff ? (new DateTime(kickoff)).toISOString() : '',
    sport: '', // have no data
    sportFamily,
    sportEmoji: getSportFamilyEmoji(sportFamily),
    region: '', // have no data
    league: '', // have no data
    url: options?.url || '',
    home: host.name || '',
    away: guest.name || '',
    logoHome: host.logo || '',
    logoAway: guest.logo || '',
  };
}
